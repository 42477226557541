import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeAheadArrayDTO } from '../../shared/infrastructure/api/dto/typeAheadArrayDTO';

const initialState = {
    parameterInitialState: [] as unknown as TypeAheadArrayDTO,
};

const slice = createSlice({
    name: 'parameters',
    initialState,
    reducers: {
        setParameters: (state, action: PayloadAction<TypeAheadArrayDTO>) => {
            // eslint-disable-next-line no-param-reassign
            state.parameterInitialState = [...action.payload];
        },
    },
});

export const ParametersReducer = slice.reducer;
export const ParametersActions = slice.actions;

export class AppRoutes {
    public static MAINTENANCE = '/maintenance';

    public static LOGIN = '/login/*';
    public static LOGIN_SIGN_IN = '/login/sign-in';
    public static LOGIN_SIGN_UP = '/login/signUp';

    public static PRE_SAMPLES = '/preregistrations';
    public static SAMPLES = '/samples';
    public static MAPS = '/maps';
    public static PARAMETERS = '/parameters';
    public static ALERTS = '/alerts';
    public static CONTAINERS = '/containers/*';
    public static CONTAINERS_ALL = '/containers/all';
    public static CONTAINERS_REQUEST_BUDGET = '/containers/requestBudget';
    public static PROFILE = '/profile';

}

import React from 'react';
import './app.scss';
import '../translations/index';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { ReduxStore } from './redux/reduxStore';
import { AppRouting } from './appRouting';

export const App = (): React.ReactElement => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                staleTime: Infinity,
            },
        },
    });
    return (
        <Provider store={ReduxStore}>
            <QueryClientProvider client={queryClient}>
                <AppRouting />
            </QueryClientProvider>
        </Provider>
    );
};

export default App;

import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import React, { lazy, Suspense } from 'react';
import { AppRoutes } from './shared/application/routing/appRoutes';
import { Spinner } from './shared/application/components/spinner/spinner';
import { MaintenanceScreen } from './maintenance/application/maintenanceScreen';
import { useIsUnderMaintenance } from './shared/application/hooks/useIsUnderMaintenance';

const DashBoardPrivateRoutes = lazy(() => import('./shared/application/routing/privateRoutes'));
const DashBoardPublicRoutes = lazy(() => import('./shared/application/routing/publicRoutes'));

export const AppRouting = (): React.ReactElement => {
    const isUnderMaintenance = useIsUnderMaintenance();

    return (
        <BrowserRouter>
            {isUnderMaintenance ? (
                <Routes>
                    <Route
                        path={AppRoutes.MAINTENANCE}
                        element={(
                            <MaintenanceScreen />
                        )}
                    />
                    <Route
                        path="/*"
                        element={
                            <Navigate replace to={AppRoutes.MAINTENANCE} />
                        }
                    />
                </Routes>
            ) : (
                <Routes>
                    <Route
                        path={AppRoutes.LOGIN}
                        element={(
                            <Suspense fallback={<Spinner />}>
                                <DashBoardPublicRoutes />
                            </Suspense>
                        )}
                    />
                    <Route
                        path="/*"
                        element={(
                            <Suspense fallback={<Spinner />}>
                                <DashBoardPrivateRoutes />
                            </Suspense>
                        )}
                    />
                </Routes>
            )}
        </BrowserRouter>
    );
};

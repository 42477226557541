import { configureStore } from '@reduxjs/toolkit';
import { GlobalFilterReducer } from './globalFilter/globalFilterSlice';
import { ClientsReducer } from './clients/clientsSlice';
import { ParametersReducer } from './parameters/parametersSlice';

export const ReduxStore = configureStore({
    reducer: {
        globalFilter: GlobalFilterReducer,
        clients: ClientsReducer,
        parameters: ParametersReducer,
    },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof ReduxStore.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof ReduxStore.dispatch;

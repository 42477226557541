import React from 'react';
import { useTranslation } from 'react-i18next';
import {
    UNDER_MAINTENANCE,
    UNDER_MAINTENANCE_APOLOGIZE_TEXT_1,
    UNDER_MAINTENANCE_APOLOGIZE_TEXT_2,
    UNDER_MAINTENANCE_APOLOGIZE_TEXT_3,
} from '../../../../../../translations/tr';
import { ReactComponent as LogoEurofins } from '../../../../../../assets/img/logo/logoEurofins.svg';

export const MaintenanceView = (): React.ReactElement => {
    const { t } = useTranslation();

    return (
        <div className="login-page">
            <div className="wrapper-login-container">
                <div className="login-container">
                    <div className="login-header">
                        <div className="login-logo">
                            <LogoEurofins />
                        </div>
                        <div className="login-text">
                            <p className="title">{t(UNDER_MAINTENANCE)}</p>
                            <p className="subtitle">{t(UNDER_MAINTENANCE_APOLOGIZE_TEXT_1)}</p>
                            <p className="subtitle">{t(UNDER_MAINTENANCE_APOLOGIZE_TEXT_2)}</p>
                            <p className="subtitle">{t(UNDER_MAINTENANCE_APOLOGIZE_TEXT_3)}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

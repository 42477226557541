import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import './spinner.scss';

export const Spinner = (): React.ReactElement => {
    return (
        <div className="spinner-wrapper">
            <CircularProgress />
        </div>
    );
};

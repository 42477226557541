export const LOGIN_LABEL = 'LOGIN_LABEL';
export const HOME = 'HOME';
export const LOGOUT = 'LOGOUT';
export const THIS_FIELD_IS_REQUIRED = 'THIS_FIELD_IS_REQUIRED';
export const EMAIL_OR_USERNAME_LABEL = 'EMAIL_OR_USERNAME_LABEL';
export const PASSWORD_LABEL = 'PASSWORD_LABEL';
export const PASSWORD_FORGOTTEN = 'PASSWORD_FORGOTTEN';
export const ASK_FOR_ACCESS = 'ASK_FOR_ACCESS';
export const INVALID_CREDENTIALS = 'INVALID_CREDENTIALS';
export const LOGIN_TITLE = 'LOGIN_TITLE';
export const LOGIN_SUBTITLE = 'LOGIN_SUBTITLE';
export const PRIVACY_POLICY_TERMS_AND_CONDITIONS = 'PRIVACY_POLICY_TERMS_AND_CONDITIONS';
export const REQUEST_ACCESS_MODAL_TITLE = 'REQUEST_ACCESS_MODAL_TITLE';
export const REQUEST_ACCESS_MODAL_INFO = 'REQUEST_ACCESS_MODAL_INFO';
export const REQUEST = 'REQUEST';
export const CANCEL = 'CANCEL';
export const FILTER_SAMPLES_TITLE = 'FILTER_SAMPLES_TITLE';
export const FILTER_PARAMETERS_TITLE = 'FILTER_PARAMETERS_TITLE';
export const FILTER_SAMPLES_SUBTITLE = 'FILTER_SAMPLES_SUBTITLE';
export const FILTER_PARAMETERS_SUBTITLE = 'FILTER_PARAMETERS_SUBTITLE';
export const CLIENT_LABEL = 'CLIENT_LABEL';
export const ORIGIN_LABEL = 'ORIGIN_LABEL';
export const STATES_LABEL = 'STATES_LABEL';
export const PERIOD_LABEL = 'PERIOD_LABEL';
export const SAMPLE_NUMBER = 'SAMPLE_NUMBER';
export const RESET_FILTERS = 'RESET_FILTERS';
export const PERIOD_START_DATE = 'PERIOD_START_DATE';
export const PERIOD_FINAL_DATE = 'PERIOD_FINAL_DATE';
export const START_NUMBER = 'START_NUMBER';
export const FINAL_NUMBER = 'FINAL_NUMBER';
export const STATE_VALIDATED = 'STATE_VALIDATED';
export const STATE_VALIDATED_PLURAL = 'STATE_VALIDATED_PLURAL';
export const STATE_ANALYZING = 'STATE_ANALYZING';
export const STATE_WITH_ALERTS = 'STATE_WITH_ALERTS';
export const STATE_WITH_RESULT_ALERTS = 'STATE_WITH_RESULT_ALERTS';
export const STATE_REGISTERED = 'STATE_REGISTERED';
export const STATE_REGISTERED_PLURAL = 'STATE_REGISTERED_PLURAL';
export const DISPLAY_ADDITIONAL_RESULTS = 'DISPLAY_ADDITIONAL_RESULTS';
export const NO_MATCHES_FOUND = 'NO_MATCHES_FOUND';
export const SAMPLES = 'SAMPLES';
export const MAP = 'MAP';
export const PARAMETERS = 'PARAMETERS';
export const ALERTS = 'ALERTS';
export const CONTAINERS = 'CONTAINERS';
export const DOCUMENTATION = 'DOCUMENTATION';
export const PROFILE = 'PROFILE';
export const ENGLISH = 'ENGLISH';
export const SPANISH = 'SPANISH';
export const ID_LIMS = 'ID_LIMS';
export const CUSTOMER_FILTER_LABEL = 'CUSTOMER_FILTER_LABEL';
export const ORIGIN_FILTER_LABEL = 'ORIGIN_FILTER_LABEL';
export const STATES_FILTER_LABEL = 'STATES_FILTER_LABEL';
export const PARAMETER_FILTER_LABEL = 'PARAMETER_FILTER_LABEL';
export const START_DATE_FILTER_LABEL = 'START_DATE_FILTER_LABEL';
export const FINAL_DATE_FILTER_LABEL = 'FINAL_DATE_FILTER_LABEL';
export const START_SAMPLE_NUMBER_FILTER_LABEL = 'START_SAMPLE_NUMBER_FILTER_LABEL';
export const FINAL_SAMPLE_NUMBER_FILTER_LABEL = 'FINAL_SAMPLE_NUMBER_FILTER_LABEL';
export const DENOMINATION = 'DENOMINATION';
export const REFERENCE = 'REFERENCE';
export const RECEPTION = 'RECEPTION';
export const SHOT = 'SHOT';
export const INITIAL_SHOT = 'INITIAL_SHOT';
export const FINAL_SHOT = 'FINAL_SHOT';
export const ANALYSIS_TYPE = 'ANALYSIS_TYPE';
export const STATUS = 'STATUS';
export const OBSERVATION = 'OBSERVATION';
export const NO_RESULTS = 'NO_RESULTS';
export const NO_RESULTS_MESSAGE = 'NO_RESULTS_MESSAGE';
export const ADD_PREREGISTRATION = 'ADD_PREREGISTRATION';
export const ADD_PARAMETER = 'ADD_PARAMETER';
export const ADD = 'ADD';
export const MODIFY = 'MODIFY';
export const START_ANALYSIS = 'START_ANALYSIS';
export const FINAL_ANALYSIS = 'FINAL_ANALYSIS';
export const ESTIMATED = 'ESTIMATED';
export const SAMPLE_ALERTS = 'SAMPLE_ALERTS';
export const SAMPLE_INCIDENCES = 'SAMPLE_INCIDENCES';
export const ADD_ALERT = 'ADD_ALERT';
export const ADD_INCIDENCE = 'ADD_INCIDENCE';
export const SEARCH_BY_DENOMINATION = 'SEARCH_BY_DENOMINATION';
export const PARAMETER = 'PARAMETER';
export const METHOD = 'METHOD';
export const LIMIT = 'LIMIT';
export const ROUND = 'ROUND';
export const UNITS = 'UNITS';
export const LOADING = 'LOADING';
export const CREATE_GLOBAL_ALERT = 'CREATE_GLOBAL_ALERT';
export const CREATE_GLOBAL_ALERT_FORM_SUBTITLE = 'CREATE_GLOBAL_ALERT_FORM_SUBTITLE';
export const DESCRIPTION = 'DESCRIPTION';
export const PARAMETER_TO_CONTROL = 'PARAMETER_TO_CONTROL';
export const SELECT_SAMPLE = 'SELECT_SAMPLE';
export const ALARM_TYPE = 'ALARM_TYPE';
export const RESULT = 'RESULT';
export const ALERT = 'ALERT';
export const ALERT_VALIDITY = 'ALERT_VALIDITY';
export const VALID_UNTIL = 'VALID_UNTIL';
export const VALID_UNTIL_END_OF_ANALYSIS = 'VALID_UNTIL_END_OF_ANALYSIS';
export const UNTIL_END_OF_ANALYSIS = 'UNTIL_END_OF_ANALYSIS';
export const CREATE = 'CREATE';
export const UNIT = 'UNIT';
export const BIGGER_THAN = 'BIGGER_THAN';
export const LOWER_THAN = 'LOWER_THAN';
export const ALERT_NOTIFICATION_RESULT_TYPE = 'ALERT_NOTIFICATION_RESULT_TYPE';
export const ALERT_NOTIFICATION_UNIT_TYPE = 'ALERT_NOTIFICATION_UNIT_TYPE';
export const ESTABLISHES_A_DATE = 'ESTABLISHES_A_DATE';
export const ALERT_TYPE_ADVICE = 'ALERT_TYPE_ADVICE';
export const ADD_CLIENT_REQUIRED = 'ADD_CLIENT_REQUIRED';
export const ADD_ALERT_VALIDITY_DATE_REQUIRED = 'ADD_ALERT_VALIDITY_DATE_REQUIRED';
export const ADD_ALERT_MAX_MIN_REQUIRED = 'ADD_ALERT_MAX_MIN_REQUIRED';
export const ADD_ALERT_RANGE_ERROR = 'ADD_ALERT_RANGE_ERROR';
export const ADD_ALERT_PARAMETER_UNIT_ERROR = 'ADD_ALERT_PARAMETER_UNIT_ERROR';
export const CONTAINER_SEVERAL_SIZES = 'CONTAINER_SEVERAL_SIZES';
export const CONTAINER_TYPE = 'CONTAINER_TYPE';
export const CONTAINER_SIZES = 'CONTAINER_SIZES';
export const CONTAINERS_ADDED = 'CONTAINERS_ADDED';
export const CART = 'CART';
export const ADD_CONTAINERS_INSTRUCTIONS = 'ADD_CONTAINERS_INSTRUCTIONS';
export const MODIFY_CONTAINER = 'MODIFY_CONTAINER';
export const ALERT_DATE = 'ALERT_DATE';
export const ALERT_TYPE = 'ALERT_TYPE';
export const PARAM = 'PARAM';
export const SAVE = 'SAVE';
export const DELETE = 'DELETE';
export const EDIT = 'EDIT';
export const DELETE_CONTAINER = 'DELETE_CONTAINER';
export const DELETE_CONTAINER_TEXT = 'DELETE_CONTAINER_TEXT';
export const REQUEST_CONTAINERS = 'REQUEST_CONTAINERS';
export const REQUEST_CONTAINERS_TEXT = 'REQUEST_CONTAINERS_TEXT';
export const REQUEST_CONTAINERS_NOTES = 'REQUEST_CONTAINERS_NOTES';
export const REQUEST_CONTAINERS_SENT_TITLE = 'REQUEST_CONTAINERS_SENT_TITLE';
export const REQUEST_CONTAINERS_SENT_DESCRIPTION = 'REQUEST_CONTAINERS_SENT_DESCRIPTION';
export const END_OF_ANALYSIS = 'END_OF_ANALYSIS';
export const SAMPLE_ALERT_ADD_EDIT_HEADER = 'SAMPLE_ALERT_ADD_EDIT_HEADER';
export const ACCEPT = 'ACCEPT';
export const SAMPLE_ALERT_SUCCESSFUL_MESSAGE = 'SAMPLE_ALERT_SUCCESSFUL_MESSAGE';
export const SAMPLE_ALERT_SUCCESSFUL_TITLE = 'SAMPLE_ALERT_SUCCESSFUL_TITLE';
export const ALERT_REMOVE_MODAL_TITLE = 'ALERT_REMOVE_MODAL_TITLE';
export const ALERT_REMOVE_MODAL_CONTENT = 'ALERT_REMOVE_MODAL_CONTENT';
export const SAMPLE_ALERT_EDIT_HEADER = 'SAMPLE_ALERT_EDIT_HEADER';
export const SEE_VALIDATED_DOCUMENT = 'SEE_VALIDATED_DOCUMENT';
export const EXPORT_RESULTS_TO_EXCEL = 'EXPORT_RESULTS_TO_EXCEL';
export const SAMPLE_INCIDENCE_ADD_HEADER = 'SAMPLE_INCIDENCE_ADD_HEADER';
export const ADD_INCIDENCE_INSTRUCTIONS = 'ADD_INCIDENCE_INSTRUCTIONS';
export const TITLE_LABEL = 'TITLE_LABEL';
export const ADD_INCIDENCE_ERROR = 'ADD_INCIDENCE_ERROR';
export const INCIDENCES = 'INCIDENCES';
export const INCIDENCE_SUCCESSFUL_MESSAGE = 'INCIDENCE_SUCCESSFUL_MESSAGE';
export const SAMPLE_ADD_TITLE = 'SAMPLE_ADD_TITLE';
export const SAMPLE_ADD_FORM_SUBTITLE = 'SAMPLE_ADD_FORM_SUBTITLE';
export const CONTINUE = 'CONTINUE';
export const BUDGET_LABEL = 'BUDGET_LABEL';
export const SECTION_LABEL = 'SECTION_LABEL';
export const SAMPLE_TYPE_LABEL = 'SAMPLE_TYPE_LABEL';
export const PARAMETERS_LABEL = 'PARAMETERS_LABEL';
export const QR_NUMBER_LABEL = 'QR_NUMBER_LABEL';
export const ADD_PARAMETERS_INSTRUCTIONS = 'ADD_PARAMETERS_INSTRUCTIONS';
export const DATE_LABEL = 'DATE_LABEL';
export const HOUR_LABEL = 'HOUR_LABEL';
export const ADDITIONAL_PARAMETER_QUESTION = 'ADDITIONAL_PARAMETER_QUESTION';
export const ADDITIONAL_PARAMETER_INSTRUCTIONS = 'ADDITIONAL_PARAMETER_INSTRUCTIONS';
export const ADDITIONAL_CONTAINER_QUESTION = 'ADDITIONAL_CONTAINER_QUESTION';
export const ADDITIONAL_CONTAINER_INSTRUCTIONS = 'ADDITIONAL_CONTAINER_INSTRUCTIONS';
export const ADD_CONTAINER = 'ADD_CONTAINER';
export const REQUEST_OBSERVATIONS_INSTRUCTIONS = 'REQUEST_OBSERVATIONS_INSTRUCTIONS';
export const LIMIT_DATE = 'LIMIT_DATE';
export const URGENCY_REASON = 'URGENCY_REASON';
export const QR_VALIDATION_ERROR_TITLE = 'QR_VALIDATION_ERROR_TITLE';
export const QR_VALIDATION_ERROR_INFO = 'QR_VALIDATION_ERROR_INFO';
export const SELECT_PARAMETERS = 'SELECT_PARAMETERS';
export const BACK = 'BACK';
export const SAMPLE_ADD_CONTAINER_TITLE = 'SAMPLE_ADD_CONTAINER_TITLE';
export const PARAMETER_ERROR_MODAL_TITLE = 'PARAMETER_ERROR_MODAL_TITLE';
export const PARAMETER_ERROR_MODAL_INFO = 'PARAMETER_ERROR_MODAL_INFO';
export const COMPLETE_ALL_FIELDS = 'COMPLETE_ALL_FIELDS';
export const ADDITIONAL_PARAMETER_TO_BUDGET = 'ADDITIONAL_PARAMETER_TO_BUDGET';
export const GLOBAL_ALERT_SUCCESSFUL_MESSAGE = 'GLOBAL_ALERT_SUCCESSFUL_MESSAGE';
export const SAMPLE_SENT_TITLE = 'SAMPLE_SENT_TITLE';
export const SAMPLE_SENT_INFO = 'SAMPLE_SENT_INFO';
export const NOTIFICATIONS_TITLE = 'NOTIFICATIONS_TITLE';
export const SEE_DETAILS = 'SEE_DETAILS';
export const PRESAMPLES = 'PRESAMPLES';
export const QR = 'QR';
export const FILTER_PRESAMPLES_TITLE = 'FILTER_PRESAMPLES_TITLE';
export const MODIFY_PASSWORD = 'MODIFY_PASSWORD';
export const MODIFY_PASSWORD_INSTRUCTIONS = 'MODIFY_PASSWORD_INSTRUCTIONS';
export const NOTIFICATION_SETTING = 'NOTIFICATION_SETTING';
export const RECEIVE_NOTIFICATIONS = 'RECEIVE_NOTIFICATIONS';
export const RECEIVE_NOTIFICATIONS_DESCRIPTION = 'RECEIVE_NOTIFICATIONS_DESCRIPTION';
export const PARAMETER_HISTORICAL = 'PARAMETER_HISTORICAL';
export const EXPORT_TO_EXCEL = 'EXPORT_TO_EXCEL';
export const ENTRY_DATE_LABEL = 'ENTRY_DATE_LABEL';
export const SAMPLE_STATUS = 'SAMPLE_STATUS';
export const SINAC_FILE = 'SINAC_FILE';
export const EXPORT_EXCEL_RESULT_TITLE = 'EXPORT_EXCEL_RESULT_TITLE';
export const EXPORT_EXCEL_RESULT_INSTRUCTIONS = 'EXPORT_EXCEL_RESULT_INSTRUCTIONS';
export const YES = 'YES';
export const NO = 'NO';
export const LOCALITY_LABEL = 'LOCALITY_LABEL';
export const SEE_DETAIL = 'SEE_DETAIL';
export const CREATE_PREREGISTRATION = 'CREATE_PREREGISTRATION';
export const PREREGISTRATION_ADD_CONTAINER_TITLE = 'PREREGISTRATION_ADD_CONTAINER_TITLE';
export const GENERIC_ERROR = 'GENERIC_ERROR';
export const ALL_CLIENTS = 'ALL_CLIENTS';
export const ALL_ORIGINS = 'ALL_ORIGINS';
export const SELECT_A_CLIENT = 'SELECT_A_CLIENT';
export const SECTION_FIELD_REQUEST = 'SECTION_FIELD_REQUEST';
export const SAMPLE_TYPE_FIELD_REQUEST = 'SAMPLE_TYPE_FIELD_REQUEST';
export const BUDGET_PARAMETERS_FIELD_REQUEST = 'BUDGET_PARAMETERS_FIELD_REQUEST';
export const SINAC_ERROR_INSTRUCTIONS = 'SINAC_ERROR_INSTRUCTIONS';
export const SINAC_ERROR_TITLE = 'SINAC_ERROR_TITLE';
export const PRESAMPLE_RESULT_TITLE = 'PRESAMPLE_RESULT_TITLE';
export const PRESAMPLE_RESULT_MESSAGE = 'PRESAMPLE_RESULT_MESSAGE';
export const PRESAMPLE_RESULT_BUTTON = 'PRESAMPLE_RESULT_BUTTON';
export const COMPLETE_PROCESS = 'COMPLETE_PROCESS';
export const DRAFT_NOTICATION_INFO = 'DRAFT_NOTICATION_INFO';
export const HIDE_PRESAMPLES = 'HIDE_PRESAMPLES';
export const PRESAMPLE_NOTIFICATION = 'PRESAMPLE_NOTIFICATION';
export const PRESAMPLE_QR_VALIDATION_INSTRUCTIONS = 'PRESAMPLE_QR_VALIDATION_INSTRUCTIONS';
export const ADD_NEW = 'ADD_NEW';
export const PRESAMPLE_ID_LABEL = 'PRESAMPLE_ID_LABEL';
export const PRESAMPLE_ORIGIN_LABEL = 'PRESAMPLE_ORIGIN_LABEL';
export const PRESAMPLE_BUDGET_LABEL = 'PRESAMPLE_BUDGET_LABEL';
export const PRESAMPLE_SECTION_LABEL = 'PRESAMPLE_SECTION_LABEL';
export const ALL_PARAMETERS = 'ALL_PARAMETERS';
export const OBSERVATIONS_LABEL = 'OBSERVATIONS_LABEL';
export const CONTAINERS_COUNTER_INFO = 'CONTAINERS_COUNTER_INFO';
export const CONTAINER_COUNTER_INFO = 'CONTAINER_COUNTER_INFO';
export const INSITU_DATA = 'INSITU_DATA';
export const INSITU_DATA_INSTRUCTION = 'INSITU_DATA_INSTRUCTION';
export const ADD_DATA = 'ADD_DATA';
export const SAVE_DRAFT = 'SAVE_DRAFT';
export const SELECT_RESPONSIBLE = 'SELECT_RESPONSIBLE';
export const SELECT_RESPONSIBLE_MODAL_TITLE = 'SELECT_RESPONSIBLE_MODAL_TITLE';
export const SELECT_RESPONSIBLE_MODAL_INSTRUCTIONS = 'SELECT_RESPONSIBLE_MODAL_INSTRUCTIONS';
export const COMPLETE_PRESAMPLE = 'COMPLETE_PRESAMPLE';
export const SELECT_PARAMETERS_INSITU = 'SELECT_PARAMETERS_INSITU';
export const ADD_PARAMETERS_INSITU_INSTRUCTIONS = 'ADD_PARAMETERS_INSITU_INSTRUCTIONS';
export const IN_PROGRESS = 'IN_PROGRESS';
export const VALIDATED_PRESAMPLE_MODAL_INFO = 'VALIDATED_PRESAMPLE_MODAL_INFO';
export const VALIDATED_PRESAMPLE_MODAL_INSTRUCTIONS = 'VALIDATED_PRESAMPLE_MODAL_INSTRUCTIONS';
export const DRAFT_SENT_TITLE = 'DRAFT_SENT_TITLE';
export const DRAFT_SENT_INFO = 'DRAFT_SENT_INFO';
export const GLOBAL_FILTER_FORM_ERROR = 'GLOBAL_FILTER_FORM_ERROR';
export const EXPORT_EXCEL = 'EXPORT_EXCEL';
export const ERROR_EXCEL_TITLE = 'ERROR_EXCEL_TITLE';
export const ERROR_EXCEL_INSTRUCTIONS = 'ERROR_EXCEL_INSTRUCTIONS';
export const EXCEL_RESULTS = 'EXCEL_RESULTS';
export const EXCEL_TYPES_OF_ANALYSIS = 'EXCEL_TYPES_OF_ANALYSIS';
export const PRESAMPLE_CODE = 'PRESAMPLE_CODE';
export const SINAC = 'SINAC';
export const SENT = 'SENT';
export const REQUEST_ACCESS_BODY = 'REQUEST_ACCESS_BODY';
export const REQUEST_ACCESS_BODY_PHONE = 'REQUEST_ACCESS_BODY_PHONE';
export const REQUEST_ACCESS_BODY_EMAIL = 'REQUEST_ACCESS_BODY_EMAIL';
export const EXCEL_ERROR_MODAL_MAX_LIMIT_ENTRIES_TITLE = 'EXCEL_ERROR_MODAL_MAX_LIMIT_ENTRIES_TITLE';
export const EXCEL_ERROR_MODAL_MAX_LIMIT_ENTRIES = 'EXCEL_ERROR_MODAL_MAX_LIMIT_ENTRIES';
export const ALERT_NOTIFICATION_UNIT_TYPE_GREATER_THAN_X = 'ALERT_NOTIFICATION_UNIT_TYPE_GREATER_THAN_X';
export const ALERT_NOTIFICATION_UNIT_TYPE_LESS_THAN_X = 'ALERT_NOTIFICATION_UNIT_TYPE_LESS_THAN_X';
export const UNIT_VALUE_PLACEHOLDER = 'UNIT_VALUE_PLACEHOLDER';
export const STATE_FIELD_DISCLAIMER = 'STATE_FIELD_DISCLAIMER';
export const CONTAINER_WARNING_BANNER = 'CONTAINER_WARNING_BANNER';
export const DOWNLOAD_VALIDATED_DOCUMENTS = 'DOWNLOAD_VALIDATED_DOCUMENTS';
export const ONLY_VALIDATED_SAMPLES_ERROR = 'ONLY_VALIDATED_SAMPLES_ERROR';
export const MAXIMUM_NUMBER_OF_DOCUMENTS_ALLOWED_ERROR = 'MAXIMUM_NUMBER_OF_DOCUMENTS_ALLOWED_ERROR';
export const DOWNLOAD_ONLY_SAMPLES_WITH_NEW_SYSTEM_URL_ERROR = 'DOWNLOAD_ONLY_SAMPLES_WITH_NEW_SYSTEM_URL_ERROR';
export const VALIDATED_DOCUMENTS_ERROR_TITLE = 'VALIDATED_DOCUMENTS_ERROR_TITLE';
export const VALIDATED_DOCUMENTS_ERROR_INSTRUCTIONS = 'VALIDATED_DOCUMENTS_ERROR_INSTRUCTIONS';
export const UNDER_MAINTENANCE = 'UNDER_MAINTENANCE';
export const UNDER_MAINTENANCE_APOLOGIZE_TEXT_1 = 'UNDER_MAINTENANCE_APOLOGIZE_TEXT_1';
export const UNDER_MAINTENANCE_APOLOGIZE_TEXT_2 = 'UNDER_MAINTENANCE_APOLOGIZE_TEXT_2';
export const UNDER_MAINTENANCE_APOLOGIZE_TEXT_3 = 'UNDER_MAINTENANCE_APOLOGIZE_TEXT_3';
export const CONTAINERS_BY_BUDGET_TAB_NAME = 'CONTAINERS_BY_BUDGET_TAB_NAME';
export const CONTAINERS_BY_BUDGET_TEXT = 'CONTAINERS_BY_BUDGET_TEXT';
export const CONTAINERS_BY_BUDGET_BUTTON = 'CONTAINERS_BY_BUDGET_BUTTON';
export const ALL_CONTAINERS_TAB_NAME = 'ALL_CONTAINERS_TAB_NAME';
export const ALERTS_NOTIFICATION_TEXT = 'ALERTS_NOTIFICATION_TEXT';
export const CONTAINERS_FORM_DESCRIPTION_TEXT = 'CONTAINERS_FORM_DESCRIPTION_TEXT';
export const ANALYSIS_NUMBER_LABEL = 'ANALYSIS_NUMBER_LABEL';
export const ADD_COMMENT_LABEL = 'ADD_COMMENT_LABEL';
export const CONTAINER_REQUEST_LIST_EMPTY_TITLE = 'CONTAINER_REQUEST_LIST_EMPTY_TITLE';
export const CONTAINER_REQUEST_LIST_NO_FORM_TEXT = 'CONTAINER_REQUEST_LIST_NO_FORM_TEXT';
export const CONTAINER_REQUEST_LIST_EMPTY_TEXT = 'CONTAINER_REQUEST_LIST_EMPTY_TEXT';
export const CONTAINER_REQUEST_NO_PERMISSIONS_ERROR_TITLE = 'CONTAINER_REQUEST_NO_PERMISSIONS_ERROR_TITLE';
export const CONTAINER_REQUEST_NO_PERMISSIONS_ERROR_TEXT = 'CONTAINER_REQUEST_NO_PERMISSIONS_ERROR_TEXT';
export const ANALYSIS_NUMBER_ERROR = 'ANALYSIS_NUMBER_ERROR';
export const CLOSE = 'CLOSE';

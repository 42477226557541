import { AppRoutes } from '../routing/appRoutes';

export const useIsUnderMaintenance = (): boolean => {
    const checkIsUnderMaintenance = (): boolean => {
        const actualDate = new Date().getTime();
        const fromDate = process.env.REACT_APP_MAINTENANCE_FROM_DATE
            ? new Date(process.env.REACT_APP_MAINTENANCE_FROM_DATE).getTime()
            : undefined;
        const toDate = process.env.REACT_APP_MAINTENANCE_TO_DATE
            ? new Date(process.env.REACT_APP_MAINTENANCE_TO_DATE).getTime()
            : undefined;

        if (!fromDate || !toDate) {
            return false;
        }

        return actualDate <= toDate && actualDate >= fromDate;
    };

    const doRedirectIfIsUnderMaintenance = (): void => {
        if (checkIsUnderMaintenance()) {
            if (window.location.pathname !== AppRoutes.MAINTENANCE) {
                window.location.href = AppRoutes.MAINTENANCE;
            }
        } else if (
            window.location.pathname === AppRoutes.MAINTENANCE
        ) {
            window.location.href = AppRoutes.LOGIN_SIGN_IN;
        }
    };

    setInterval(() => {
        doRedirectIfIsUnderMaintenance();
    }, 1000);

    return checkIsUnderMaintenance();
};

import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import translationsEn from './languages/translations.en.json';
import translationsEs from './languages/translations.es.json';
import { LocalStorageKeys } from '../app/shared/infrastructure/localStorageKeys';

const localLanguage: string = localStorage.getItem(LocalStorageKeys.LANGUAGE) !== null
    ? localStorage.getItem(LocalStorageKeys.LANGUAGE) as string
    : 'es';

i18next
    .use(initReactI18next)
    .init({
        lng: localLanguage,
        fallbackLng: navigator.language,
        resources: {
            en: {
                translation: translationsEn,
            },
            es: {
                translation: translationsEs,
            },
        },
    })
    .then((): null => null);

export default i18next;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { STATUS_TYPE } from '../../shared/domain/valueObjects/status';

export interface GlobalFilterRegisters {
    customerId: number
    customerName: string
    originId: number
    originName: string
    startDate: string
    finalDate: string
    states: STATUS_TYPE[] | []
    parameterId: number
    parameterName: string
    startNumber: string
    finalNumber: string
}

export enum GlobalFilterRegistersNames {
    CUSTOMER_ID = 'customerId',
    CUSTOMER_NAME = 'customerName',
    ORIGIN_ID = 'originId',
    ORIGIN_NAME = 'originName',
    STATES = 'states',
    START_DATE = 'startDate',
    FINAL_DATE = 'finalDate',
    PARAMETER_ID = 'parameterId',
    PARAMETER_NAME = 'parameterName',
    START_NUMBER = 'startNumber',
    FINAL_NUMBER = 'finalNumber',
}

const initialState = {
    registers: {
        customerId: 0,
        customerName: '',
        originId: 0,
        originName: '',
        states: [],
        startDate: '',
        finalDate: '',
        parameterId: 0,
        parameterName: '',
        startNumber: '',
        finalNumber: '',
    } as GlobalFilterRegisters,
};

const slice = createSlice({
    name: 'GlobalFilter',
    initialState,
    reducers: {
        setGlobalFilter: (state, action: PayloadAction<GlobalFilterRegisters>) => {
            // eslint-disable-next-line no-param-reassign, no-unused-vars
            state.registers = action.payload;
        },
        setStartAndFinalDate: (state, action: PayloadAction<{startDate: string, finalDate: string}>) => {
            // eslint-disable-next-line no-param-reassign
            state.registers.startDate = action.payload.startDate;
            // eslint-disable-next-line no-param-reassign
            state.registers.finalDate = action.payload.finalDate;
        },
    },
});

export const GlobalFilterReducer = slice.reducer;
export const GlobalFilterActions = slice.actions;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TypeAheadArrayDTO } from '../../shared/infrastructure/api/dto/typeAheadArrayDTO';

const initialState = {
    clientsInitialState: [] as unknown as TypeAheadArrayDTO,
    isLoading: true,
};

const slice = createSlice({
    name: 'clients',
    initialState,
    reducers: {
        setClientsState: (state, action: PayloadAction<TypeAheadArrayDTO>) => {
            // eslint-disable-next-line no-param-reassign
            state.clientsInitialState = [...action.payload];
        },
    },
});

export const ClientsReducer = slice.reducer;
export const ClientsActions = slice.actions;
